import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const getTestimonials = getBaseUrl2();
const aproved_update = getBaseUrl2();
const deleteTestimonial = getBaseUrl() + "admin/deleteTestimonial";
const addTestimonial = getBaseUrl() + "admin/addTestimonial";
const updateTestimonial = getBaseUrl() + "admin/updateTestimonial";
const upload_vendor_payslip = getBaseUrl2() + "api/admin/upload_vendor_payslip";

export const admin_getvendor_by_id_api = async (id) => {
  let config = {
    method: "get",
    url: getBaseUrl2() + `api/admin/vendor/${id}`,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const admin_updatevendor_details_api = async (id, data) => {
  let config = {
    method: "patch",
    url: getBaseUrl2() + `api/admin/vendor/${id}`,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const upload_vendor_payslip_api = async (data) => {
  let config = {
    method: "post",
    url: upload_vendor_payslip,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const verifydoc_api = async (id, data) => {
  let config = {
    method: "patch",
    url: getBaseUrl() + `api/admin/vendors/${id}/docs`,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateTestimonial_api = async (data) => {
  let config = {
    method: "patch",
    url: updateTestimonial,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addTestimonial_api = async (data) => {
  let config = {
    method: "post",
    url: addTestimonial,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteTestimonial_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteTestimonial,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const admin_vendorstatus_api = async (data, id) => {
  let config = {
    method: "patch",
    url: aproved_update + `api/admin/vendor/${id}`,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const status_updateTestimonial_api = async (id) => {
  let config = {
    method: "patch",
    url: aproved_update + `api/admin/appr_vendor/${id}`,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    // data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const vendor_details_api = async (id) => {
  let config = {
    method: "get",
    url: getBaseUrl2() + `api/admin/vendor/${id}`,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getTestimonials_api = async (data) => {
  let config = {
    method: "post",
    url: getTestimonials + `api/admin/vendor`,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getvendorbookingbyid_api = async (data) => {
  let config = {
    method: "post",
    url: getTestimonials + `api/admin/vendors/bookings`,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getvendorbooking_api = async (data) => {
  let config = {
    method: "post",
    url:
      getBaseUrl2() +
      `api/admin/vendor?cat_id=${data.cat_id}&city_id=${data.city_id}`,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

//  this is used for update the value of the commission
let update_commission = getBaseUrl() + "api/admin/serviceCharge";

export const updateCommission_api = async (data) => {
  let config = {
    method: "post",
    url: update_commission,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
