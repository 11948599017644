import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./testimonial.module.css";
import { useNavigate } from "react-router-dom";
import { Grid, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { BiFilter, BiSearch } from "react-icons/bi";
import DeletePopup from "../Dialogbox/DeletePopup";
import FilterPopup from "../Dialogbox/FilterPopup";
import {
  admin_vendorstatus_api,
  deleteTestimonial_api,
  getTestimonials_api,
  status_updateTestimonial_api,
  updateCommission_api,
} from "../api/testimonial";
import { send_vendor_msg_api } from "../api/admin";
import { MdMessage } from "react-icons/md";
import Vendormessage from "../Dialogbox/Vendormessage";
const VendorList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [alltestimonial, setalltestimonial] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState();
  const [deletedialobbox, setdeletedialobbox] = useState(false);
  const [deletename, setdeletename] = useState();
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  const [vendormsgpopup, setvendormsgpopup] = useState(false);
  const [vendordata, setvendordata] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchalltestimonialFunc();
  }, [pageCount, search, isModalOpen]);

  async function fetchalltestimonialFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getTestimonials_api(temp);
      if (res.data.status) {
        setpageLength(res?.data?.data?.totalPages);
        setalltestimonial(res?.data?.data.vendors);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deleteroleFunc() {
    setisLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await deleteTestimonial_api(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletedialobbox(false);
        fetchalltestimonialFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const testimonial_status = async (data, e) => {
    setisLoading(true);
    const fd = new FormData();
    fd.append("id", data.id);
    fd.append("status", data.status == true ? false : true);
    try {
      let res = await status_updateTestimonial_api(data._id);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        fetchalltestimonialFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };
  const admin_vendorstatus = async (data, e) => {
    setisLoading(true);
    const temp = {
      status: data.status == true ? "false" : "true",
    };

    try {
      let res = await admin_vendorstatus_api(temp, data._id);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        fetchalltestimonialFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };
  const send_vendor_msg = async (data) => {
    setisLoading(true);
    const temp = {
      msg: "Hii",
      vendor_id: data._id,
    };

    try {
      let res = await send_vendor_msg_api(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  //  this is for the modal

  console.log(isModalOpen, " this is the valu for modal open");
  const closeModal = () => setIsModalOpen(false);
  const [idObject, setidObject] = useState({ vendorId: "", serviceId: "" });

  const openModal = () => setIsModalOpen(true);
  const handlePopUp = (row, data) => {
    console.log(row, data, " this is for the value of the row");
    setidObject({ vendorId: row?._id, serviceId: data?._id });
    setIsModalOpen(true);
    console.log(" this is for checking the modal is open or not");
  };

  return (
    <div className="container">
      <div className={s["user-list-heading"]}>
        <div className={s["role-list-title"]}>
          <h3>Vendor List</h3>
        </div>
        <div className={s["user-list-search"]}>
          <div className={s["search-box"]}>
            <span style={{ paddingRight: "0.5rem" }}>
              <BiSearch size={23} />
            </span>
            <input
              type="text"
              spellCheck="false"
              onChange={(e) => {
                setpageCount(1);
                setsearch(e.target.value);
              }}
              placeholder="Search name..."
            />
          </div>
          <div className={s["filter-btn"]}>
            <span style={{ paddingRight: "2px" }}>
              <BiFilter size={20} />
            </span>
            Filter
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell align="center">Image</StyledTableCell> */}
              <StyledTableCell align="center">Name</StyledTableCell>
              {/* <StyledTableCell align="center">City</StyledTableCell> */}
              <StyledTableCell align="center" style={{ width: "500px" }}>
                Sub_Categories
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "12rem" }}>
                Services
              </StyledTableCell>
              <StyledTableCell align="center">Status1</StyledTableCell>
              <StyledTableCell align="center">Status2</StyledTableCell>
              <StyledTableCell align="center">Message</StyledTableCell>
              <StyledTableCell align="center">Edit</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
              <StyledTableCell align="center">Assign</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alltestimonial?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/vendor-details", { state: row })}
                >
                  <div>
                    <p>
                      <b>Name:</b>
                      {row?.name}
                    </p>
                    <p>
                      <b>Email:</b>
                      {row?.email}
                    </p>
                    <p>
                      <b>Phone:</b>
                      {row?.phone}
                    </p>
                    <p>
                      <b>City:</b>
                      {row?.city}
                    </p>
                    <p>-----------------------</p>
                  </div>
                </StyledTableCell>
                {/* <StyledTableCell align="center" style={{ width: "40%" }}>
                  {row?.city}
                </StyledTableCell> */}
                <StyledTableCell style={{ width: "500px" }}>
                  <div>For Female:</div>
                  {(row?.sub_categories_female).map((data, index) => (
                    <div>
                      {data?.name}
                      <p>---------------</p>
                    </div>
                  ))}
                  <div>For Male:</div>
                  {(row?.sub_categories_male).map((data, index) => (
                    <div>
                      {data?.name}
                      <p>---------------</p>
                    </div>
                  ))}
                </StyledTableCell>
                <StyledTableCell>
                  <div style={{ width: "30rem" }}>
                    {(row?.services).map((data, index) => (
                      <div style={{ display: "flex" }}>
                        <div style={{ minWidth: "21rem" }}>
                          {`${data?.name} -----${data?.commission || null}%`}
                          <p>---------------</p>
                        </div>
                        <div
                          style={{
                            minWidth: "9rem",
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <div className={s["title"]}>
                            {data?.commission ? (
                              <div onClick={() => handlePopUp(row, data)}>
                                Edit
                              </div>
                            ) : (
                              <div onClick={() => handlePopUp(row, data)}>
                                Add
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => admin_vendorstatus(row)}
                    className={`${
                      row?.status === true ? s?.active_admin : s?.inactive_admin
                    }`}
                  >
                    {`${row?.status === true ? "Active" : "InActive"}`}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => testimonial_status(row)}
                    className={`${
                      row?.approved === true
                        ? s?.active_admin
                        : s?.inactive_admin
                    }`}
                  >
                    {`${row?.approved === true ? "Approved" : "unapproved"}`}
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setvendordata(row);
                    setvendormsgpopup(true);
                  }}
                >
                  <MdMessage size={20} />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CiEdit
                    onClick={() => navigate("/vendor-profile", { state: row })}
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() =>
                      navigate("/allocate-to-vendor", { state: row })
                    }
                  >
                    Assign
                  </button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => navigate("/vendor-profile", { state: row })}
                  >
                    View
                  </button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {alltestimonial?.length <= 0 && <DataNotFound />}
        {alltestimonial?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination
              count={pageLength}
              size="large"
              style={{ color: "#D21903" }}
              onChange={(e, value) => setpageCount(value)}
              page={pageCount}
            />
          </div>
        )}
      </div>
      <DeletePopup
        open={deletedialobbox}
        name={deletename}
        close={() => setdeletedialobbox(!deletedialobbox)}
        onsubmit={() => {
          deleteroleFunc();
        }}
      />
      <Vendormessage
        open={vendormsgpopup}
        data={vendordata}
        close={() => setvendormsgpopup(!vendormsgpopup)}
      />
      <FilterPopup open={false} />
      <Loder loading={isLoading} />
      <Modal isOpen={isModalOpen} onClose={closeModal} ids={idObject} />
    </div>
  );
};

export default VendorList;

function Modal({ isOpen, onClose, ids }) {
  const [commission, setcommission] = useState("");
  console.log(ids, "this are ithe ids for use in the Modal");
  const addCommissionFunc = async (data) => {
    let temp = {
      vendorId: ids?.vendorId,
      serviceId: ids?.serviceId,
      commission: commission,
    };
    try {
      let res = await updateCommission_api(temp);
      if (res.data.status) {
        onClose();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error.response);
      notificationHandler({ type: "danger", msg: "status False!" });
    }
  };

  console.log(isOpen, "in am in the funcction ");
  return (
    <>
      {isOpen && (
        <div
          className="modal"
          tabIndex="1"
          style={{ display: "block", marginTop: "10rem" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Commission</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={onClose}
                ></button>
              </div>
              <div className="modal-body">
                <div>
                  <Grid container xs={12}>
                    <Grid item xs={12}>
                      <div className="form-group">
                        <label>Commission in %</label>
                        <div>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="enter commission (%)"
                            onChange={(e) => setcommission(e.target.value)}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => addCommissionFunc()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
          {/* Optional: Overlay to close the modal */}
          {/* <div
            className="modal-backdrop"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1040,
            }}
            onClick={onClose}
          ></div> */}
        </div>
      )}
    </>
  );
}
