import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./role.module.css";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import {
  allocate_booking_api,
  confirm_booking_api,
  fetchAllrole,
  role_delete_api,
  role_status_api,
} from "../api/role";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { BiFilter, BiSearch } from "react-icons/bi";
import DeletePopup from "../Dialogbox/DeletePopup";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { getTestimonials_api, getvendorbooking_api } from "../api/testimonial";
import { RxCross2 } from "react-icons/rx";
import BookingCancel from "../Dialogbox/BookingCancel";
import xlsx from "json-as-xlsx";

const BookingListPage = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allrole, setallrole] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState();
  const [deletedialobbox, setdeletedialobbox] = useState(false);
  const [deletename, setdeletename] = useState();
  const [pageLength, setpageLength] = useState();
  const [allocatedpopup, setallocatedpopup] = useState(false);
  const [booking_id, setbooking_id] = useState(false);
  const [cat_id, setcat_id] = useState("");
  const [city_id, setcity_id] = useState("");
  const [city_name, setcity_name] = useState("");
  const [bookingcanclepopup, setbookingcanclepopup] = useState(false);
  const [search, setsearch] = useState("");
  const [cat_name, setcat_name] = useState("");
  useEffect(() => {
    fetchAllroleFunc();
  }, [pageCount, search]);

  async function fetchAllroleFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await fetchAllrole(temp);
      if (res.data.status) {
        setallrole(res?.data?.data);
        setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  const bookingconfirm = async (id) => {
    setisLoading(true);
    const temp = {
      status: "true",
    };
    try {
      let res = await confirm_booking_api(temp, id);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        fetchAllroleFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  const [alltestimonial, setalltestimonial] = useState([]);
  async function fetchalltestimonialFunc(row) {
    // setbooking_id(row._id);
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
        cat_id: row?.service?.category?._id || "",
        city_id: row?.address?.city_id || "",
      };

      let res = await getvendorbooking_api(temp);
      if (res.data.status) {
        setpageLength(res?.data?.data?.totalPages);
        setalltestimonial(res?.data?.data?.vendors);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

  //  Allocate Vendor----------------------------------------------------------------------------

  const handleCheckboxChange = async (value) => {
    console.log(value, "vendor id");
    setSelectedCheckbox(value);
    setisLoading(true);
    const temp = {
      checkout: booking_id,
      vendor: value,
    };
    try {
      let res = await allocate_booking_api(temp);
      if (res.data.status) {
        setisLoading(false);
        setSelectedCheckbox(null);
        fetchAllroleFunc();
        setallocatedpopup(false);
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  useEffect(() => {
    filtervendordata();
  }, [cat_id, city_id]);

  const filtervendordata = async () => {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
        cat_id: cat_id || "",
        city_id: city_id || "",
      };

      let res = await getvendorbooking_api(temp);
      if (res.data.status) {
        setpageLength(res?.data?.data?.totalPages);
        setalltestimonial(res?.data?.data?.vendors);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // setting for the excel file , in this headings and lenght of ther excel file is described

  let settings = {
    fileName: "Siama", // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    RTL: false, // Display the columns from right-to-left (the default value is false)
  };

  //  this code is used to export the data into  excel file
  const exportFunc = (allLeadsData) => {
    console.log(
      allLeadsData,
      "allLeadsData in rhe export function of the excel sheet"
    );

    if (allLeadsData.length < 1) {
      return notificationHandler({
        type: "error",
        msg: "All lead list is empty!",
      });
    }
    const processedData = allLeadsData.map((row, index) => ({
      ...row,
      serialNumber: index + 1,
    }));
    let data = [
      {
        sheet: "Booking Sheet",
        columns: [
          { label: "SN. NO.", value: "serialNumber" },
          {
            label: "Agent Name",
            value: (row) =>
              row?.assignedVendor ? row.assignedVendor?.name : "",
          },
          {
            label: "Customer  Name",
            value: (row) => (row?.user ? row.user?.name : ""),
          },
          {
            label: "Revenue Gross",
            value: (row) => (row?.totalAmount ? row?.totalAmount : ""),
          },
          {
            label: "Commission Paid",
            value: (row) => (row?.totalCommission ? row?.totalCommission : ""),
          },
          {
            label: "Service Type with commission Percentage",
            value: (row) =>
              row.commissionObject
                ? row?.commissionObject
                    ?.map(
                      (item) =>
                        item?.servicename + " -" + item?.commission + "%"
                    )
                    .join(", ")
                : "",
          },
          {
            label: "Amount Paid to Agent",
            value: (row) => (row?.totalCommission ? row?.totalCommission : ""),
          },
          {
            label: "Discount Given",
            value: (row) => (row?.cuponDiscount ? row?.cuponDiscount : ""),
          },
          {
            label: "Net Revenue",
            value: (row) =>
              row?.totalCommission
                ? row?.totalAmount - row?.totalCommission
                : "",
          },
        ],
        content: processedData,
      },
    ];
    let callback = function (sheet) {
      console.log("Download complete:", sheet);
    };
    try {
      xlsx(data, settings, callback);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <div className={s["role-list-title"]}>
        <h3>Booking List</h3>
        <div className={s["title"]}>
          <span
            onClick={() => exportFunc(allrole?.checkout)}
            style={{ paddingRight: "2px" }}
          >
            Export to Excel
          </span>
        </div>
      </div>

      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Service Name</StyledTableCell>
              <StyledTableCell align="center">Address</StyledTableCell>
              <StyledTableCell align="center">Amount</StyledTableCell>
              <StyledTableCell align="center">Scheduled Date</StyledTableCell>
              <StyledTableCell align="center">Payment Type</StyledTableCell>
              <StyledTableCell align="center">Assigned Vendor</StyledTableCell>
              <StyledTableCell align="center">Total Amount</StyledTableCell>
              <StyledTableCell align="center">Total Commission</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Order Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allrole.checkout?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{row?.user?.name} </StyledTableCell>
                <StyledTableCell>
                  {row?.products.map((data) => (
                    <div>
                      <p>
                        <b>Service Name:</b>
                        {data?.productId?.name}
                      </p>
                      <p>
                        <b>Service Mode:</b>
                        {data?.productId?.service_mode}
                      </p>
                      <p>
                        <b>Service Charge:</b>
                        {data?.productId?.service_charge}
                      </p>
                      <p>
                        <b>Quantity:</b>
                        {data?.quantity}
                      </p>
                      --------------------------------------------
                    </div>
                  ))}{" "}
                </StyledTableCell>
                <StyledTableCell>
                  <div>
                    <p>
                      <b>Land Mark:</b>
                      {`${row?.address?.building} ${row?.address?.address}`}
                    </p>
                    <p>
                      <b></b>
                    </p>
                  </div>
                </StyledTableCell>
                <StyledTableCell>{row.payableAmount} </StyledTableCell>
                <StyledTableCell>{row?.slot?.slice(0, 10)} </StyledTableCell>
                <StyledTableCell>{row?.paymentType} </StyledTableCell>

                <StyledTableCell>{row?.assignedVendor?.name}</StyledTableCell>
                <StyledTableCell>{row?.payableAmount}</StyledTableCell>
                <StyledTableCell>{row?.totalCommission}</StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        row.status === "failed"
                          ? "red"
                          : row.status === "pending"
                          ? "#f0c907"
                          : row.status === "success"
                          ? "Grey"
                          : row.status === "allocated"
                          ? "silver"
                          : row.status === "initiated"
                          ? "Orange"
                          : "red",
                    }}
                    className={`${
                      row.status === "booked" ||
                      row.booking_status === "delivered" ||
                      row.booking_status === "confirmed"
                        ? s.active_admin
                        : s.inactive_admin
                    }`}
                  >
                    {`${row.status === "booked" ? "Booked" : row.status}`}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    className={`${
                      row.orderStatus === "assigned" ||
                      row.orderStatus === "pending"
                        ? s.active_admin
                        : s.inactive_admin
                    }`}
                  >
                    {row.orderStatus}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {/* {row.status === "success" && (
                    <div className="" style={{ display: "flex", justifyContent: "space-between" }}>
                      <button type="button" class="btn btn-success" onClick={() => bookingconfirm(row._id)}>
                        Confirm
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={() => {
                          setbookingcanclepopup(true);
                          setbooking_id(row._id);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )} */}
                  {(row.status === "pending" || row.status === "success") &&
                    (row.orderStatus === "pending" ||
                      row.orderStatus === "rejected") && (
                      <div style={{ display: "flex", gap: "3px" }}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            setcity_id(row?.address?.city_id);
                            setcat_id(row?.service?.category?._id);
                            setcat_name(row?.service?.category?.name);
                            setcity_name(row?.address?.city);
                            setbooking_id(row?._id);
                            fetchalltestimonialFunc(row);
                            setallocatedpopup(!allocatedpopup);
                          }}
                        >
                          Allocate Vendor
                        </button>
                      </div>
                    )}

                  {/* {row.status === "success" && (
                    <div className="" style={{ display: "flex", gap: "3px" }}>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => {
                          setcity_id(row?.address?.city_id);
                          setcat_id(row?.service?.category?._id);
                          setcat_name(row?.service?.category?.name);
                          setcity_name(row?.address?.city);
                          setbooking_id(row?._id);
                          fetchalltestimonialFunc(row);
                          setallocatedpopup(!allocatedpopup);
                        }}
                      >
                        Allocate Vendor
                      </button>
                    </div>
                  )} */}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allrole.length <= 0 && <DataNotFound />}
        {allrole?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination
              count={pageLength}
              size="large"
              style={{ color: "#D21903" }}
              onChange={(e, value) => setpageCount(value)}
              page={pageCount}
            />
          </div>
        )}
      </div>

      <Dialog
        open={allocatedpopup}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="true"
        onClose={() => setallocatedpopup(!allocatedpopup)}
      >
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to Allocated</div>
          <div style={{ display: "flex" }}>
            <div className="seleced">
              {city_id && (
                <span className="seleced_item">
                  {city_name}
                  <RxCross2
                    onClick={() => {
                      setcity_id("");
                      filtervendordata();
                    }}
                    style={{
                      color: "var(--main-color)",
                      marginLeft: "0.3rem",
                      cursor: "pointer",
                    }}
                  />
                </span>
              )}
              {cat_id && (
                <span className="seleced_item">
                  {cat_name}
                  <RxCross2
                    onClick={() => {
                      setcat_id("");
                      filtervendordata();
                    }}
                    style={{
                      color: "var(--main-color)",
                      marginLeft: "0.3rem",
                      cursor: "pointer",
                    }}
                  />
                </span>
              )}
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="beat_table">
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Action</StyledTableCell>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">City</StyledTableCell>
                  <StyledTableCell align="center">Services</StyledTableCell>
                  <StyledTableCell align="center">Phone</StyledTableCell>
                  <StyledTableCell align="center">Add Vendor</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alltestimonial?.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="center">
                      <input
                        type="checkbox"
                        value={selectedCheckbox}
                        checked={row._id === selectedCheckbox}
                      />
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {row?.name}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: "40%" }}>
                      {row?.email}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.city}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {(row?.services).map((data, index) => (
                        <div>
                          {data?.name}
                          <p>---------------</p>
                        </div>
                      ))}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.phone}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <button
                        onClick={() => handleCheckboxChange(row._id)}
                        className="p-1  btn btn-primary"
                      >
                        Add
                      </button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {alltestimonial?.length <= 0 && <DataNotFound />}
            {alltestimonial?.length > 0 && (
              <div className={s["pagination"]}>
                <Pagination
                  count={pageLength}
                  size="large"
                  style={{ color: "#D21903" }}
                  onChange={(e, value) => setpageCount(value)}
                  page={pageCount}
                />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Loder loading={isLoading} />
      <BookingCancel
        open={bookingcanclepopup}
        booking_id={booking_id}
        close={() => setbookingcanclepopup(!bookingcanclepopup)}
        onsubmit={() => fetchAllroleFunc()}
      />
    </div>
  );
};

export default BookingListPage;
